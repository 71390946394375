import { graphql, useStaticQuery } from 'gatsby';
import { useCallback, useEffect, useState } from 'react';
import {
  initialMarketingConsents,
  MarketingConsents,
  usePolicyDetails,
} from 'state/formData/policyDetails';
import { MarketingProduct } from './businessConstants';

export type MarketingConsentCheckboxData = {
  id: string;
  label: string;
  marketingProduct: MarketingProduct;
};

type CsMarketingConsents = {
  csPetAboutYourPolicy: {
    marketing_consent: {
      checkbox_section: {
        john_lewis_and_partners_label: string;
        waitrose_and_partners_label: string;
        john_lewis_financial_services_label: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPolicy {
      marketing_consent {
        checkbox_section {
          john_lewis_and_partners_label
          waitrose_and_partners_label
          john_lewis_financial_services_label
        }
      }
    }
  }
`;

const useMarketingConsents = (): {
  marketingConsentCheckboxData: MarketingConsentCheckboxData[];
  isMarketingProductChecked: (marketingProduct: MarketingProduct) => boolean;
  updateMarketingConsents: (update: boolean, marketingProduct: MarketingProduct) => void;
} => {
  const {
    john_lewis_and_partners_label,
    waitrose_and_partners_label,
    john_lewis_financial_services_label,
  } = useStaticQuery<CsMarketingConsents>(
    query
  ).csPetAboutYourPolicy.marketing_consent.checkbox_section;

  const [
    { marketingConsents, marketingConsentFlag },
    updatePolicyDetails,
  ] = usePolicyDetails();

  const [marketingConsent, setMarketingConsent] = useState<MarketingConsents>(
    marketingConsents ?? initialMarketingConsents
  );

  const marketingConsentCheckboxData = [
    {
      id: 'john-lewis-financial-services-marketing-consent-checkbox',
      label: john_lewis_financial_services_label,
      marketingProduct: MarketingProduct.JOHN_LEWIS_FINANCIAL_SERVICES,
    },
    {
      id: 'john-lewis-marketing-consent-checkbox',
      label: john_lewis_and_partners_label,
      marketingProduct: MarketingProduct.JOHN_LEWIS,
    },
    {
      id: 'waitrose-marketing-consent-checkbox',
      label: waitrose_and_partners_label,
      marketingProduct: MarketingProduct.WAITROSE,
    },
  ];

  useEffect(() => {
    updatePolicyDetails({
      marketingConsentFlag,
      marketingConsents: marketingConsent,
    });
  }, [marketingConsentFlag, marketingConsent, updatePolicyDetails]);

  const isMarketingProductChecked = (marketingProduct: MarketingProduct): boolean => {
    switch (marketingProduct) {
      case MarketingProduct.JOHN_LEWIS:
        return marketingConsent.johnLewis;
      case MarketingProduct.WAITROSE:
        return marketingConsent.waitrose;
      case MarketingProduct.JOHN_LEWIS_FINANCIAL_SERVICES:
        return marketingConsent.johnLewisFinancialServices;
      default:
        return false;
    }
  };

  const updateMarketingConsentState = useCallback(
    (update: Partial<MarketingConsents>) =>
      setMarketingConsent((state) => ({
        ...state,
        ...update,
      })),
    [setMarketingConsent]
  );

  const updateMarketingConsents = (
    update: boolean,
    marketingProduct: MarketingProduct
  ): void => {
    switch (marketingProduct) {
      case MarketingProduct.JOHN_LEWIS:
        updateMarketingConsentState({ johnLewis: update });
        break;
      case MarketingProduct.WAITROSE:
        updateMarketingConsentState({ waitrose: update });
        break;
      case MarketingProduct.JOHN_LEWIS_FINANCIAL_SERVICES:
        updateMarketingConsentState({ johnLewisFinancialServices: update });
        break;
      default:
        break;
    }
  };

  return {
    marketingConsentCheckboxData,
    isMarketingProductChecked,
    updateMarketingConsents,
  };
};

export default useMarketingConsents;
