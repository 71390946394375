export type VariationType = 'Original' | 'Variation #1';

declare global {
  interface Window {
    optimizelyConfig: {
      [experimentName: string]: { variantId: VariationType };
    };
  }
}

export const getOptimizelyVariation = (
  experimentName: string | undefined
): VariationType => {
  if (
    typeof window === 'undefined' ||
    window.optimizelyConfig === undefined ||
    experimentName === undefined
  ) {
    return 'Original';
  }

  return window.optimizelyConfig[experimentName]?.variantId ?? 'Original';
};
