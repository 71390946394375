import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import PanelWithActionLink from 'components/PanelWithActionLink';
import RichTextLarge from 'components/RichTextLarge';

export const PrivacyPolicy = styled(RichTextLarge)`
  margin-top: ${spacing(2)};

  ${RichText} {
    p {
      ${mediaQuery.tabletPortrait`
        ${fonts.paragraphLarge}
      `}
    }
  }
`;

export const PrivacyPolicyHeading = styled.h2`
  ${fonts.headingSmall}

  margin: ${spacing(6)} 0 ${spacing(2)};
`;

export const HeadingMedium = styled.h2`
  ${fonts.headingMedium}
`;

export const PanelWithActionLinkWithMargins = styled(PanelWithActionLink)`
  margin: ${spacing(2)} 0 ${spacing(4)};

  && {
    ${mediaQuery.tabletPortrait`
      padding: ${spacing(4)};
    `}
  }
`;

export const ParagraphLargeWithMargins = styled.p`
  ${fonts.paragraphLarge};
  margin: ${spacing(4)} 0 ${spacing(2)};
`;
