import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import {
  dateToDateValue,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isAggsQuote } from 'businessLogic/aggregators';
import {
  isYoungestPetUnder8Weeks,
  useFirstEligibleStartDateFromToday,
} from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useReducer } from 'react';
import FormFooter from 'components/FormFooter';
import RichTextWithModal from 'components/RichTextWithModal';
import { areDateValuesEqual, formatLongDateWithOrdinalSuffix } from 'helpers/dateHelpers';
import {
  PageTitle,
  trackFieldError,
  trackLinkClick,
  trackRichTextLinkClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import CoverStartDateQuestion from './CoverStartDateQuestion';
import MarketingConsentSection from './MarketingConsentSection';
import useMarketingConsentRules from './MarketingConsentSection/validation';
import useAboutYourPolicyQuestions from './questions';
import {
  HeadingMedium,
  PanelWithActionLinkWithMargins,
  ParagraphLargeWithMargins,
  PrivacyPolicy,
  PrivacyPolicyHeading,
} from './styles';

type AboutYourPolicyFormProps = {
  moveNext: () => void;
  moveBack: () => void;
  gridItemProps?: GridItemProps;
};

type AboutYourPolicyFormData = {
  csPetAboutYourPolicy: {
    next_button_text: string;
    privacy_policy_section_heading: string;
    privacy_policy: string;
    cover_start_date: {
      heading: string;
      important_information: string;
      start_date_panel: {
        your_cover_will_start_today_label: string;
        your_cover_will_start_label: string;
        choose_a_different_start_date_text: string;
        choose_a_different_start_date_screenreader_text: string;
        hide_calendar_text: string;
        hide_calendar_screenreader_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPolicy {
      next_button_text
      privacy_policy_section_heading
      privacy_policy
      cover_start_date {
        heading
        important_information
        start_date_panel {
          your_cover_will_start_today_label
          your_cover_will_start_label
          choose_a_different_start_date_text
          choose_a_different_start_date_screenreader_text
          hide_calendar_text
          hide_calendar_screenreader_text
        }
      }
    }
  }
`;

const AboutYourPolicyForm: React.FC<AboutYourPolicyFormProps> = ({
  moveNext,
  moveBack,
  gridItemProps,
}) => {
  const {
    csPetAboutYourPolicy: {
      next_button_text,
      privacy_policy,
      privacy_policy_section_heading,
      cover_start_date: { heading, important_information, start_date_panel },
    },
  } = useStaticQuery<AboutYourPolicyFormData>(query);

  const quote = useCurrentQuote();
  const aggsQuote = isAggsQuote(quote);

  const questions = useAboutYourPolicyQuestions();
  const [petsDetails] = usePetsDetails();
  const [policyDetails] = usePolicyDetails();
  const marketingConsentRules = useMarketingConsentRules();

  const privacyPolicySectionId = 'privacy-policy-section';

  const coverStartDateUtc = new Date(policyDetails.coverStartDate);
  const [minDate] = useFirstEligibleStartDateFromToday();

  const formattedCoverStartDate = formatLongDateWithOrdinalSuffix(
    coverStartDateUtc,
    false
  );

  const { getError, validateOnSubmit } = useValidation(
    policyDetails,
    !isAggsQuote(quote) ? marketingConsentRules : {},
    trackFieldError
  );

  const [showCalendar, toggleCalendar] = useReducer((state) => !state, false);

  const petNames = petsDetails.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);

  const youngPetExplanatoryText = petNameReplacer(
    combinedPetNames,
    questions.coverStartDate.youngPetExplanatoryText ?? ''
  );

  const isStartDateToday = areDateValuesEqual(
    dateToDateValue(new Date()),
    dateToDateValue(parseDate(policyDetails.coverStartDate))
  );

  const startDateText = isStartDateToday
    ? petNameReplacer(
        combinedPetNames,
        start_date_panel.your_cover_will_start_today_label
      )
    : petNameReplacer(combinedPetNames, start_date_panel.your_cover_will_start_label);

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <HeadingMedium>{heading}</HeadingMedium>
      <RichTextWithModal
        html={important_information}
        pageTitle={PageTitle.AboutYourPolicy}
      />
      {isYoungestPetUnder8Weeks(petsDetails) && (
        <ParagraphLargeWithMargins id="young-pet-is-under-8-weeks-text">
          {youngPetExplanatoryText}
        </ParagraphLargeWithMargins>
      )}
      <PanelWithActionLinkWithMargins
        id="cover-start-panel"
        summaryText={startDateText}
        heading={formattedCoverStartDate}
        link={{
          onClick: () => {
            toggleCalendar();
            trackLinkClick(
              PageTitle.CoverStartDate,
              showCalendar
                ? start_date_panel.hide_calendar_text
                : start_date_panel.choose_a_different_start_date_text
            );
          },
          text: showCalendar
            ? start_date_panel.hide_calendar_text
            : start_date_panel.choose_a_different_start_date_text,
          screenreaderText: showCalendar
            ? start_date_panel.hide_calendar_screenreader_text
            : start_date_panel.choose_a_different_start_date_screenreader_text,
        }}
      />
      {showCalendar && <CoverStartDateQuestion minDate={minDate} />}
      {!aggsQuote && (
        <MarketingConsentSection
          getError={getError}
          pageTitle={PageTitle.AboutYourPolicy}
          gridItemProps={gridItemProps}
        />
      )}
      <section aria-labelledby={privacyPolicySectionId}>
        <PrivacyPolicyHeading id={privacyPolicySectionId}>
          {privacy_policy_section_heading}
        </PrivacyPolicyHeading>
        <PrivacyPolicy
          pageTitle={PageTitle.AboutYourPolicy}
          html={privacy_policy}
          onLinkClick={trackRichTextLinkClick(PageTitle.AboutYourPolicy)}
        />
      </section>
      <FormFooter
        moveNextButton={{
          text: next_button_text,
          onClick: () =>
            trackTextButtonClick(PageTitle.AboutYourPolicy, 'Submit details'),
        }}
        backButton={{
          onClick: () => {
            moveBack();
            trackTextButtonClick(PageTitle.AboutYourPolicy, 'Move back');
          },
        }}
        pageTitle={PageTitle.AboutYourPolicy}
      />
    </form>
  );
};

export default AboutYourPolicyForm;
