import { StyledLabel } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/Label/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';

export const HeadingMediumWithMargins = styled.h2`
  ${fonts.headingMedium};
  margin-top: ${spacing(8)};
  margin-bottom: ${spacing(4)};
`;

export const LightParagraphQuestionField = styled(QuestionField)`
  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(-2)};
  `};

  && {
    ${StyledLabel} {
      ${fonts.paragraphLarge};
    }
  }
`;

export const Container = styled.div`
  margin-bottom: ${spacing(6)};
`;
