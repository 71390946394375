import MultipleCheckboxInput from '@rsa-digital/evo-shared-components/components/Form/MultipleCheckboxInput';
import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import QuestionField from 'components/QuestionField';
import useAboutYourPolicyQuestions from 'forms/AboutYourPolicyForm/questions';
import { MarketingProduct } from 'helpers/businessConstants';
import { trackCheckboxClick } from 'helpers/eventTracking';
import { getOptimizelyVariation } from 'helpers/optimizely';
import useMarketingConsents from 'helpers/useMarketingConsents';
import { PolicyDetails, usePolicyDetails } from 'state/formData/policyDetails';
import {
  Container,
  HeadingMediumWithMargins,
  LightParagraphQuestionField,
} from './styles';

type MarketingConsentSectionData = {
  csPetAboutYourPolicy: {
    marketing_consent: {
      section_heading: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPolicy {
      marketing_consent {
        section_heading
      }
    }
  }
`;

type MarketingConsentSectionProps = {
  getError: FieldFunction<PolicyDetails, string | undefined>;
  gridItemProps?: GridItemProps;
  pageTitle?: string;
} & ComponentProps;

const MarketingConsentSection: React.FC<MarketingConsentSectionProps> = ({
  getError,
  gridItemProps,
  pageTitle,
  ...props
}) => {
  const { section_heading } = useStaticQuery<MarketingConsentSectionData>(
    query
  ).csPetAboutYourPolicy.marketing_consent;

  const [{ marketingConsentFlag }, updatePolicyDetails] = usePolicyDetails();
  const questions = useAboutYourPolicyQuestions();

  const {
    updateMarketingConsents,
    isMarketingProductChecked,
    marketingConsentCheckboxData,
  } = useMarketingConsents();

  const radioInputId = 'marketing-consent-boolean-radio-input';
  const checkboxOptions = marketingConsentCheckboxData.map((option) => ({
    id: option.id,
    label: option.label,
    checked: isMarketingProductChecked(option.marketingProduct),
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      updateMarketingConsents(e.target.checked, option.marketingProduct);
      trackCheckboxClick(e.target.checked ? 'select' : 'deselect', option.label);
    },
  }));

  // ========================================================================================
  // =       Optimizely A/B Experiment - JL Financial Services only          =
  // ========================================================================================

  const optimizelyJLOnlyVariation = getOptimizelyVariation(
    process.env.GATSBY_OPTIMIZELY_LJONLY_EXPERIMENT_NAME
  );
  const isOptimizelyNewVariation = optimizelyJLOnlyVariation === 'Variation #1';

  // - If not isOptimizelyNewVariation  => We show existing variation
  // - If isOptimizelyNewVariation      => We show JL Only variation

  return (
    <Container data-cy="marketing-consent-section">
      <HeadingMediumWithMargins>{section_heading}</HeadingMediumWithMargins>
      {isOptimizelyNewVariation ? (
        <QuestionField
          {...componentProps(props)}
          gridItemProps={gridItemProps}
          question={{
            ...questions.jlfsConsent,
            questionText: questions.jlfsConsent.questionText,
          }}
          initiallyShowTooltip
          errorText={getError('marketingConsentFlag')}>
          <BooleanRadioInput
            id={radioInputId}
            value={marketingConsentFlag}
            onChange={(newValue) => {
              updatePolicyDetails({
                marketingConsentFlag: newValue,
              });
              updateMarketingConsents(
                newValue,
                MarketingProduct.JOHN_LEWIS_FINANCIAL_SERVICES
              );
            }}
            yesLabel={questions.jlfsConsent.yesLabel}
            noLabel={questions.jlfsConsent.noLabel}
          />
        </QuestionField>
      ) : (
        <QuestionField
          {...componentProps(props)}
          gridItemProps={gridItemProps}
          question={{
            ...questions.marketingConsent,
            questionText: questions.marketingConsent.questionText,
          }}
          initiallyShowTooltip
          errorText={getError('marketingConsentFlag')}>
          <BooleanRadioInput
            id={radioInputId}
            value={marketingConsentFlag}
            onChange={(newValue) => {
              updatePolicyDetails({
                marketingConsentFlag: newValue,
              });
            }}
            yesLabel={questions.marketingConsent.yesLabel}
            noLabel={questions.marketingConsent.noLabel}
          />
        </QuestionField>
      )}
      {marketingConsentFlag && !isOptimizelyNewVariation && (
        <LightParagraphQuestionField
          {...componentProps(props)}
          question={{
            ...questions.marketingPartners,
            questionText: questions.marketingPartners.questionText,
          }}
          errorText={getError('marketingConsents')}>
          <MultipleCheckboxInput
            id="marketing-consent-checkboxes"
            options={checkboxOptions}
          />
        </LightParagraphQuestionField>
      )}
    </Container>
  );
};

export default MarketingConsentSection;
