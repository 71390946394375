import { validateIf } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { PolicyDetails } from 'state/formData/policyDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';

type csAboutYourPolicyErrorMessages = {
  csPetAboutYourPolicyQuestionsV2: {
    marketing_consent: CsErrorsMissingOnly;
    marketing_partners: CsErrorsMissingOnly;
  };
};

const query = graphql`
  query {
    csPetAboutYourPolicyQuestionsV2 {
      marketing_consent {
        error_messages {
          missing
        }
      }
      marketing_partners {
        error_messages {
          missing
        }
      }
    }
  }
`;

const useMarketingConsentRules = (): ValidationRules<PolicyDetails> => {
  const errors = useStaticQuery<csAboutYourPolicyErrorMessages>(query)
    .csPetAboutYourPolicyQuestionsV2;

  const marketingConsentGiven = (data: PolicyDetails): boolean =>
    data.marketingConsentFlag === true;

  return {
    marketingConsentFlag: [
      {
        validityCondition: (value) => value !== undefined,
        errorMessage: errors.marketing_consent.error_messages.missing,
      },
    ],
    marketingConsents: validateIf(marketingConsentGiven, [
      {
        validityCondition: (value) =>
          value.johnLewis || value.waitrose || value.johnLewisFinancialServices,
        errorMessage: errors.marketing_partners.error_messages.missing,
      },
    ]),
  };
};

export default useMarketingConsentRules;
