import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

const RichTextLarge = styled(RichTextWithModal)`
  ${RichText} {
    p {
      ${fonts.paragraph}
      & {
        font-weight: 300;
      }
      ${mediaQuery.tabletLandscape`
        ${fonts.paragraphLarge}
      `}
    }
  }
`;

export default RichTextLarge;
